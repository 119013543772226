var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.attribute.setSlug(_vm.attribute.slug || _vm.attribute.name);
      }
    },
    model: {
      value: _vm.attribute.name,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "name", $$v);
      },
      expression: "attribute.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.attribute.setSlug(_vm.attribute.slug);
      }
    },
    model: {
      value: _vm.attribute.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "slug", $$v);
      },
      expression: "attribute.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.ATTRIBUTE_TYPES,
      "name": "type",
      "label": "Type"
    },
    model: {
      value: _vm.attribute.type,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "type", $$v);
      },
      expression: "attribute.type"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.fieldTypes,
      "name": "type",
      "label": "Field type"
    },
    model: {
      value: _vm.attribute.fieldType,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "fieldType", $$v);
      },
      expression: "attribute.fieldType"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.attributes,
      "item-text": "name",
      "item-value": "_id",
      "name": "parentId",
      "label": "Parent attribute"
    },
    model: {
      value: _vm.attribute.parentId,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "parentId", $$v);
      },
      expression: "attribute.parentId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "sort",
      "type": "number",
      "name": "sort",
      "label": "Display Sequence"
    },
    model: {
      value: _vm.attribute.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "sort", $$v);
      },
      expression: "attribute.sort"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    model: {
      value: _vm.attribute.description,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "description", $$v);
      },
      expression: "attribute.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "tooltip",
      "label": "Tooltip"
    },
    model: {
      value: _vm.attribute.tooltip,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "tooltip", $$v);
      },
      expression: "attribute.tooltip"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "title",
      "label": "Name display in list a space"
    },
    model: {
      value: _vm.attribute.title,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "title", $$v);
      },
      expression: "attribute.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hideFrom.filter",
      "label": "Hide from filter"
    },
    model: {
      value: _vm.attribute.hideFrom.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute.hideFrom, "filter", $$v);
      },
      expression: "attribute.hideFrom.filter"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hideFrom.create",
      "label": "Hide from List a space"
    },
    model: {
      value: _vm.attribute.hideFrom.create,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute.hideFrom, "create", $$v);
      },
      expression: "attribute.hideFrom.create"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hideFrom.view",
      "label": "Hide from Space details"
    },
    model: {
      value: _vm.attribute.hideFrom.view,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute.hideFrom, "view", $$v);
      },
      expression: "attribute.hideFrom.view"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hidePreview",
      "label": "Hide specific non selected attributes (strike through)"
    },
    model: {
      value: _vm.attribute.hidePreview,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "hidePreview", $$v);
      },
      expression: "attribute.hidePreview"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "makeSearchCombinations",
      "label": "Include this attribute in search combinations"
    },
    model: {
      value: _vm.attribute.makeSearchCombinations,
      callback: function callback($$v) {
        _vm.$set(_vm.attribute, "makeSearchCombinations", $$v);
      },
      expression: "attribute.makeSearchCombinations"
    }
  })], 1)], 1), _vm.attribute.featureSlug === 'activities' ? [_vm._l(_vm.attribute.disciplines, function (discipline, index) {
    return _c('v-card', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-title', {
      staticClass: "py-0"
    }, [_vm._v(" #" + _vm._s(index + 1) + " "), _c('v-spacer'), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": _vm.attribute.disciplines.length == 1
      },
      on: {
        "click": function click($event) {
          return _vm.removeDiscipline(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "select",
        "items": _vm.disciplines,
        "item-text": "name",
        "item-value": "_id",
        "name": "Discipline",
        "label": "Discipline"
      },
      model: {
        value: discipline._id,
        callback: function callback($$v) {
          _vm.$set(discipline, "_id", $$v);
        },
        expression: "discipline._id"
      }
    })], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "number",
        "name": "group",
        "label": "Group"
      },
      model: {
        value: discipline.group,
        callback: function callback($$v) {
          _vm.$set(discipline, "group", $$v);
        },
        expression: "discipline.group"
      }
    })], 1)], 1)], 1)], 1);
  }), _c('vx-btn', {
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addDiscipline();
      }
    }
  }, [_vm._v("Add discipline")])] : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }