<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="attribute.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="attribute.setSlug(attribute.slug || attribute.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="attribute.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="attribute.setSlug(attribute.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="attribute.type" type="select" :items="ATTRIBUTE_TYPES" name="type" label="Type" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="attribute.fieldType" type="select" :items="fieldTypes" name="type" label="Field type" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="attribute.parentId"
              type="autocomplete"
              :items="attributes"
              item-text="name"
              item-value="_id"
              name="parentId"
              label="Parent attribute"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input id="sort" v-model="attribute.sort" type="number" name="sort" label="Display Sequence" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="attribute.description"
              type="textarea"
              name="description"
              label="Description"
              counter="280"
              max="280"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="attribute.tooltip" type="textarea" name="tooltip" label="Tooltip" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="attribute.title" type="text" name="title" label="Name display in list a space" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="attribute.hideFrom.filter"
              type="checkbox"
              name="hideFrom.filter"
              label="Hide from filter"
            />
            <vx-input
              v-model="attribute.hideFrom.create"
              type="checkbox"
              name="hideFrom.create"
              label="Hide from List a space"
            />
            <vx-input
              v-model="attribute.hideFrom.view"
              type="checkbox"
              name="hideFrom.view"
              label="Hide from Space details"
            />

            <vx-input
              v-model="attribute.hidePreview"
              type="checkbox"
              name="hidePreview"
              label="Hide specific non selected attributes (strike through)"
            />

            <vx-input
              v-model="attribute.makeSearchCombinations"
              type="checkbox"
              name="makeSearchCombinations"
              label="Include this attribute in search combinations"
            />
          </v-col>
        </v-row>

        <template v-if="attribute.featureSlug === 'activities'">
          <v-card v-for="(discipline, index) in attribute.disciplines" :key="index" class="mb-4" outlined>
            <v-card-title class="py-0">
              #{{ index + 1 }}

              <v-spacer />

              <vx-btn icon :disabled="attribute.disciplines.length == 1" @click="removeDiscipline(index)">
                <vx-icon>mdi-delete</vx-icon>
              </vx-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" class="pb-0">
                  <vx-input
                    v-model="discipline._id"
                    type="select"
                    :items="disciplines"
                    item-text="name"
                    item-value="_id"
                    name="Discipline"
                    label="Discipline"
                  />
                </v-col>

                <v-col cols="12" sm="6" class="pb-0">
                  <vx-input v-model="discipline.group" type="number" name="group" label="Group" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <vx-btn outlined @click="addDiscipline()">Add discipline</vx-btn>
        </template>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Attribute } from '@tutti/models';
import { FeatureMixin } from '@tutti/mixins';
import { AttributeService } from '@tutti/services';
import { FIELD_TYPES, ATTRIBUTE_TYPES } from '@tutti/constants';

export default {
  mixins: [FeatureMixin],

  data() {
    return {
      tab: 0,
      attribute: new Attribute(),
      attributes: [],
      loading: false,
      fieldTypes: FIELD_TYPES.filter(type => type.attribute),
      ATTRIBUTE_TYPES,
    };
  },

  created() {
    this.tab = this.$route?.params?.tab || 0;

    this.getAttributes();
    this.$setFeatures({
      disciplines: true,
    });
    this.getAttribute();
  },

  methods: {
    addDiscipline() {
      this.attribute.disciplines.push({
        _id: undefined,
        group: 1,
      });
    },

    removeDiscipline(index) {
      this.attribute.disciplines.splice(index, 1);
    },

    async getAttribute() {
      if (this.$route.params.id) {
        const response = await AttributeService.getById(this.$route.params.id);
        if (response) {
          this.attribute = new Attribute(response.data);
          this.$setTitle(this.attribute.name);
        }
      } else {
        const feature = this.getFeatureById(this.$route.query.id);
        if (feature) {
          this.attribute.featureId = feature._id;
          this.attribute.featureSlug = feature.slug;
        } else {
          this.$error('Feature not exists');
          this.$previous();
        }
      }
    },

    async getAttributes() {
      const response = await AttributeService.getAll({});
      if (response) {
        this.attributes = response.data.data;
      }
    },

    async save(previous) {
      this.loading = true;
      this.attribute.isVerified = true;

      const response = await AttributeService.save(this.attribute.get());
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('attribute', response.data._id));
        }
      }

      this.loading = false;
    },
  },
};
</script>
